import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

import Container from '../Container';
import Image from '../Image';
import logo from '../../../theme/images/logo.png'
import './styles.scss';

export default class HeaderComponent extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }
    
    state = {
        showedHamburgerMenu: false,
        pages: [
            {
                name: 'Strona główna',
                slug: '/'
            },
            {
                name: 'O Firmie',
                slug: '/o-firmie'
            },
            {
                name: 'Współpraca',
                slug: '/wspolpraca'
            },
            {
                name: 'Kontakt',
                slug: '/kontakt'
            }
        ],
        fixed: false
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScroll)
    }

    showHamburgerMenu = () => {
        this.setState({
            ...this.state,
            showedHamburgerMenu: !this.state.showedHamburgerMenu
        })
    }

    onScroll = () => {
        const headerHeight = document.getElementById('header-component').clientHeight;

        if (window.scrollY > headerHeight + 150) {
            this.setState(prevState => ({
                ...prevState,
                fixed: true
            }))
        } else if (window.scrollY <= headerHeight) {
            this.setState(prevState => ({
                ...prevState,
                fixed: false
            }))
        }
    }

    render() {
        const { showedHamburgerMenu, pages, fixed } = this.state;
        const { pathname } = this.props.location;
        return (
            <div className={fixed ? 'navbar is-fixed-top animated fadeInDown' : 'navbar '} role="navigation" id="header-component">
                <Container>
                    <div className="navbar-brand">
                        <Link className="navbar-item" to="/">
                            <Image src={logo} />
                        </Link>
                        <a onClick={() => this.showHamburgerMenu()} role="button" className={`navbar-burger burger ${showedHamburgerMenu ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className={`navbar-menu ${showedHamburgerMenu ? 'is-active' : ''}`}>
                        <div className="navbar-end">
                            {pages.map(page => <Link key={page.slug} className={`navbar-item ${page.slug === pathname ? 'is-active' : ''}`} to={page.slug}>{page.name}</Link>)}
                        </div>
                    </div>
                </Container>                    
            </div>
        );
    }
}