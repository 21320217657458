import React from 'react';

import './styles.scss';

const Layout = ({ children }) => (
    /* eslint-disable */
    <div role="component" id="layout-component">
        { children }
    </div>
    /* eslint-enable */
)

export default Layout;