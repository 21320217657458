import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { StaticQuery, graphql } from 'gatsby';

import './styles.scss';
import 'leaflet/dist/leaflet.css'
import logo from '../../../theme/images/logo.png'

export default class MapComponent extends React.Component {
	state = {
		lat: 51.192040,
		lng: 20.428610,
		zoom: 16,
		showMap: false
	}


	render() {
		const position = [this.state.lat, this.state.lng]
		const { showMap } = this.state;

		return <StaticQuery 
			query={query}
			render={data => (
				<div role="component" id="page-map-component">
					{showMap ? <BaseMap data={data} position={position} state={this.state} /> : 
						<MapPlaceholder data={data} onClick={() => this.setState({ ...this.state, showMap: true })} />
					}
				</div>
			)}
		/>
		
	}
}

const BaseMap = props => {
	const { state, position } = props;

	return (
		<Map center={position} zoom={state.zoom}>
			<TileLayer
				attribution='&copy; <a href="#">OpenStreetMap</a> contributors'
				url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
			/>
				<Marker position={position}>
					<Popup>
						<img src={logo} />
						<span>Firma Produkcyjno-Handlowa MILAP</span>
					</Popup>
				</Marker>
		</Map>
	)
}

const MapPlaceholder = props => {

	return (
		<div onClick={props.onClick} className="map-placeholder" style={{ background: `url(${props.data.placeholder.childImageSharp.fluid.src})`}}>
			Kliknij aby zobaczyć mapę
		</div>
	)
}

const query = graphql`
{
    placeholder: 	file(relativePath: {eq: "map_placeholder.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2500, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}
}
`;