import React from 'react';
import PropTypes from 'prop-types'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ src, effect }) => (
    <LazyLoadImage
        src={src}
        effect={effect}
    />
)

Image.propTypes = {
    effect: PropTypes.string
}
Image.defaultProps = {
    effect: 'blur'
}

export default Image;

