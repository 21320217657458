import React from 'react';
import PropTypes from 'prop-types';

export default class Column extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        number: PropTypes.number,
        customClass: PropTypes.string
    }
    
    render() {
        const { children, number, customClass } = this.props;

        return (
            
            <div className={`column ${number ? `is-${number}` : ''} ${customClass ? customClass : ''}`}>
                { children }
            </div>
        )
    }
}