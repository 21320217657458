import React from 'react';
import PropTypes from 'prop-types'

import './styles.scss';
const ButtonComponent = props => {

    const { onClick, title, backgroundColor, fontColor } = props;
    return (
        <div role="component" id="button-component">
            <a className="button" onClick={onClick} style={{ backgroundColor: backgroundColor, color: fontColor, borderColor: fontColor }}>
                {title}
            </a>
        </div>
    )
}

ButtonComponent.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string
}

ButtonComponent.defaultProps = {
    backgroundColor: 'transparent',
    fontColor: '#000'
}

export default ButtonComponent;