import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';

import './styles.scss';
import { Link } from 'gatsby';

const BreadcrumbsComponent = props => {
    const { way } = props;

    return (
        <div role="component" id="layout-breadcrumbs-component">
            <Container>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><Link to={'/'}>MILAP</Link></li>
                        {way.map(element => (
                            <li className={element.active ? 'active' : null}><Link to={element.url}>{element.title}</Link></li>
                        ))}
                    </ul>
                </nav>
            </Container>
        </div>
    )
}

BreadcrumbsComponent.propTypes = {
    way: PropTypes.arrayOf({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        active: PropTypes.bool
    }).isRequired
}
BreadcrumbsComponent.defaultProps = {
    way: []
}

export default BreadcrumbsComponent;