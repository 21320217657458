import React from 'react';
import Columns from '../Columns';
import Column from '../Column';
import Container from '../Container';
import bkg from '../../../theme/images/footer/footer_bkg.png';
import FontAwesome from 'react-fontawesome';

import './styles.scss';

export default class FooterComponent extends React.Component {

    render() {
        return (
            <div role="component" id="footer-component" style={{ backgroundImage: `url(${bkg})`}}>
                <Container>
                    <Columns>
                        <Column number={3}>
                            <p>
                                <FontAwesome
                                    name='user'
                                    size='1x'
                                />
                                Jarosław Paszyński
                            </p>
                            <p>ul. Górna 1</p>
                            <p>26-200 Końskie</p>
                        </Column>
                        <Column number={3}>
                            <p>
                                <FontAwesome
                                    name='phone'
                                    size='1x'
                                />
                                +48 (041) 372 77 07
                            </p>
                            <p>
                                <FontAwesome
                                    name='phone'
                                    size='1x'
                                />
                                +48 (0) 603 912 016
                            </p>
                        </Column>
                        <Column number={3}>
                            <p>
                                <FontAwesome
                                    name='cogs'
                                    size='1x'
                                />
                                Dział techniczny
                            </p>
                            <p>
                                <FontAwesome
                                    name='phone'
                                    size='1x'
                                />
                                +48 507 172 200
                            </p>
                            <p>
                                <FontAwesome
                                    name='phone'
                                    size='1x'
                                />
                                +48 (041) 375 13 46
                            </p>
                        </Column>
                        <Column number={3}>
                            <p>
                                <FontAwesome
                                    name='envelope'
                                    size='1x'
                                />
                                milap@milap.pl
                            </p>
                            <p>
                                <FontAwesome
                                    name='envelope'
                                    size='1x'
                                />
                                biuro@milap.pl
                            </p>
                            <p>
                                <FontAwesome
                                    name='envelope'
                                    size='1x'
                                />
                                marketing@milap.pl
                            </p>
                        </Column>
                    </Columns>
                    <p>Copyright &copy; 2019 Firma Produkcyjno Handlowa MILAP</p>
                </Container>
            </div>
        )
    }
}