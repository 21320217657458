import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default class SEOComponent extends React.Component {
	static propTypes = {
		description: PropTypes.string,
		lang: PropTypes.string,
		meta: PropTypes.array,
		keywords: PropTypes.arrayOf(PropTypes.string),
		pageTitle: PropTypes.string.isRequired,
	}
	static defaultProps = {
		lang: `pl`,
		meta: [],
		keywords: [],
    }
    
    renderHelmet = data => {
        const { title, author, description } = data;
        const { lang, meta, keywords, pageTitle } = this.props;
        
        return <Helmet
            htmlAttributes={{
                lang,
            }}
            title={pageTitle}
            titleTemplate={`%s | ${title}`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },		
                {
                    property: `og:title`,
                    content: title,
                },		
                {
                    property: `og:description`,
                    content: description,
                },	
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },	
            ]
                .concat(
                    keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                    }
                : []
            )
            .concat(meta)}
        />	
    }

	render() {
		return (
            <StaticQuery
                query={query}
                render={data => {
                    return this.renderHelmet(data.site.siteMetadata)
                }}
            />												
		)
	}
}

export const query = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
