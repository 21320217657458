import React from 'react';
import PropTypes from 'prop-types';
import { Animated } from 'react-animated-css';
import TrackVisibility from 'react-on-screen';
import { graphql, StaticQuery, Link, useStaticQuery } from 'gatsby';
import { navigateTo } from 'gatsby';

import './styles.scss';
import Button from '../../layout/Button';

const HomeParallaxComponent = props => {
	const data = useStaticQuery(query)
	const { title, subtitle, slug, animationIn, animationOut, imageNumber } = props;
	const { fluid } = data[`image${imageNumber}`].childImageSharp;
	
    return (
        <div role="component" id="home-parallax-component">
			<section className="hero">
				<div className="hero-body">
					<div className="parallax"  style={{ backgroundImage: `url(${fluid.src})` }}>
						<div className="parallaxContent">	
						<Animated animationInDelay={200} animationIn={animationIn} animationOut={animationOut} isVisible={true}>
							{Boolean(title) && <p className="title">{title}</p>}
							{Boolean(subtitle) && <p className="subtitle">{subtitle}</p>}
							{Boolean(slug) && 
								<Button
									title="Zobacz więcej"
									fontColor="#fff"
									onClick={() => navigateTo(slug)}
								/>
							}
						</Animated>
						</div>
					</div>
				</div>
			</section>
        </div>
    )
}

HomeParallaxComponent.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	slug: PropTypes.string,
	animationIn: PropTypes.string,
	animationOut: PropTypes.string
};
HomeParallaxComponent.defaultProps = {
	imageNumber: 1
};

export default HomeParallaxComponent;

export const query = graphql`
{
    image1: 	file(relativePath: {eq: "homeSlider/1.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2500, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}
	
	image2: 	file(relativePath: {eq: "homeSlider/2.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2500, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}

	image3: 	file(relativePath: {eq: "homeSlider/3.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2500, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}

	image4: 	file(relativePath: {eq: "homeSlider/3.jpg"}) {
    	childImageSharp{
			fluid(maxWidth:2500, maxHeight: 1000){
				...GatsbyImageSharpFluid
			}
    	}
  	}
	  
}
`;