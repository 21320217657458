import React from 'react';
import preloader from '../../../theme/images/preloader.gif'

import './styles.scss';

const PreloaderComponent = props => {
    return (
        <div role="component" id="preloader-component">
            <img src={preloader} />
        </div>
    )
}

export default PreloaderComponent;
