import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Playground = props => {

    const { children } = props;

    return (
        <div role="component" id="playground-component">
            {children}
        </div>
    )
}

export default Playground;